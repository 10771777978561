//RESPONSIVE TABS
$('.responsive-tabs').responsiveTabs({
    accordionOn: ['xs'] // xs, sm, md, lg
});

//BOOTSTRAP SELECT
$('.selectpicker').selectpicker({
    style: '',
    styleBase: 'form-control',
});

// DATEPICKER
$('.datepicker_input').datepicker({
    language: 'es',
    format: 'dd/mm/yyyy',
});
