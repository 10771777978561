/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');

// Datepicker
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.es');

// jQuery Validation
require('jquery-validation');
require('jquery-validation/dist/localization/messages_es.js');

// Bootstrap Toggle
require('bootstrap4-toggle');

// Bootstrap Select
require('bootstrap-select');

// Responsive Tabs
require('bootstrap-responsive-tabs');

// Sweet Alert 2
require('sweetalert2');

require('./general');
